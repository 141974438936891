import { useMemo } from "react";
import { useLocation } from "react-router";

import { Menu } from "../constans/Menu";

const useSelectedMenuItem = () => {
  const location = useLocation();

  return useMemo(() => {
    const selectedMenuItem = Menu.find((item) =>
      location.pathname.includes(item.path)
    );

    return selectedMenuItem ? selectedMenuItem.path : Menu[0].path;
  }, [location.pathname]);
};

export default useSelectedMenuItem;
