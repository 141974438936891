import { FC } from "react";
import { FaTimes } from "react-icons/fa"; // Import the close icon
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./MobileMenu.scss";
import { Menu } from "../../constans/Menu";
import useSelectedMenuItem from "../../hooks/useSelectedMenuItem";
import Logo from "../../images/logo-1.png";
import { useTranslation, Trans } from "react-i18next";

interface MobileMenu {
  toggleMenu: CallableFunction;
}

const MobileMenu: FC<MobileMenu> = ({ toggleMenu }) => {
  const selectedMenuItem = useSelectedMenuItem();

  const { t, i18n } = useTranslation("common");

  return (
    <div className={`mobile-menu`}>
      <div className="menu-header">
        <img src={Logo} />{" "}
        <FaTimes className="close-icon" onClick={() => toggleMenu(false)} />
      </div>
      <ul className="mobile-nav">
        {Menu.map((item) => (
          <li
            key={item.path}
            className={selectedMenuItem === item.path ? "selected" : ""}
          >
            <Link to={item.path} onClick={() => toggleMenu(false)}>
              <span>{t(item.name)}</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileMenu;
